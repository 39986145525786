import React from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';

import SubLayout from '../components/SubLayout';
import { H3 } from '../components/common/H.style';
import { P } from '../components/common/P.style';
import Link from '../components/Link';
import { Img } from '../components/common/Img.style';

import WeeklySchedule from '../components/WeeklySchedule';
import ClosedDayLabel from '../components/ClosedDayLabel';
import Online365Label from '../components/Online365Label';

import ScheduleImg from '../assets/img/schedule.png';
import Pdf from '../pdf/schedule.pdf';

import { useTrackedState } from '../lib/state';

const AccentButton = styled.span`
  border: 3px solid #f7b13c;
  border-radius: 6px;
  background-color: #fffff0;
  padding: 0.5em;
  margin: 0.5em;
`;

const HeadText = styled.span`
  font-size: 18px;
  color: #ffffff;
`;
const CellText = styled.span`
  font-size: 12px;
  color: #222222;
`;
const SmallCellText = styled.span`
  font-size: 8px;
`;

const HeadFlex = styled(Flex)`
  max-width: 300px;
  height: 30px;
  background-color: #999999;
  box-sizing: border-box;
  border-top: solid 1px #707070;
  border-right: solid 1px #707070;
  border-left: solid 1px #707070;
`;
const LineFlex = styled(Flex)`
  max-width: 300px;
`;

const CellInnerFlex = styled(FlexItem)`
  width: 112px;
  box-sizing: border-box;
  border-top: solid 1px #707070;
  border-right: solid 1px #707070;
  border-bottom: solid 1px #707070;
  background-color: #ffffff;
`;
const FlexHasHeight = styled(Flex)`
  height: 50px;
`;
const TimeCommonStyle = `
  width: 90px;
  box-sizing: border-box;
  border: solid 1px #707070;
  background-color: #ffffff;
`;

const Time = ({ time, asFlex }) =>
  asFlex ? (
    <Flex
      center
      contentStretch
      alignStretch
      css={`
        height: inherit;
        flex-grow: 1;
        ${TimeCommonStyle}
      `}
    >
      <CellText>{time}</CellText>
    </Flex>
  ) : (
    <FlexItem grow={1} css={TimeCommonStyle}>
      <Flex
        center
        contentStretch
        alignStretch
        css={`
          height: 50px;
        `}
      >
        <CellText>{time}</CellText>
      </Flex>
    </FlexItem>
  );

const cloneCell = (ele) =>
  React.cloneElement(ele, {
    css: `width: 112px;
  box-sizing: border-box;
  border-top: solid 1px #707070;
  border-right: solid 1px #707070;
  border-bottom: solid 1px #707070;
  background-color: #ffffff;`,
  });

const DaySchedule = ({
  day,
  morning1,
  afternoon1,
  night1,
  morning2,
  afternoon2,
  night2,
  morning3,
  afternoon3,
  night3,
  morningHasInnerCells = false,
  afternoonHasInnerCells = false,
  nightHasInnerCells = false,
}) => (
  <Flex
    column
    css={`
      margin-top: 10px;
      margin-bottom: 10px;
    `}
  >
    <HeadFlex center>
      <HeadText>{day}</HeadText>
    </HeadFlex>
    <LineFlex>
      <Time asFlex={morningHasInnerCells} time="AM" />
      {morningHasInnerCells ? cloneCell(morning1) : <CellInnerFlex>{morning1}</CellInnerFlex>}
      {morningHasInnerCells ? cloneCell(morning2) : <CellInnerFlex>{morning2}</CellInnerFlex>}
    </LineFlex>
    <LineFlex>
      <Time asFlex={afternoonHasInnerCells} time="PM" />
      {afternoonHasInnerCells ? cloneCell(afternoon1) : <CellInnerFlex>{afternoon1}</CellInnerFlex>}
      {afternoonHasInnerCells ? cloneCell(afternoon2) : <CellInnerFlex>{afternoon2}</CellInnerFlex>}
    </LineFlex>
    <LineFlex>
      <Time asFlex={nightHasInnerCells} time="夜間" />
      {nightHasInnerCells ? cloneCell(night1) : <CellInnerFlex>{night1}</CellInnerFlex>}
      {nightHasInnerCells ? cloneCell(night2) : <CellInnerFlex>{night2}</CellInnerFlex>}
    </LineFlex>
  </Flex>
);

const makeCell =
  ({ backgroundColor, hasHeight }) =>
  ({ children, css = '', ...props }) =>
    (
      <Flex
        center
        contentStretch
        alignStretch
        css={`
          ${css}
          background-color: ${backgroundColor};
          height: ${hasHeight ? '50px' : 'inherit'};
        `}
        {...props}
      >
        {children}
      </Flex>
    );

const Close = ({ hasHeight = true, ...props }) => {
  const Cell = makeCell({ backgroundColor: '#efefef', hasHeight });
  return <Cell {...props}>{` `}</Cell>;
};

const HirahataAM = ({ hasHeight = true, ...props }) => {
  const Cell = makeCell({ backgroundColor: 'white', hasHeight });
  return (
    <Cell center column {...props}>
      <CellText>平畑院長</CellText>
      <CellText>10:00〜12:30</CellText>
    </Cell>
  );
};

const HirahataPM = ({ hasHeight = true, ...props }) => {
  const Cell = makeCell({ backgroundColor: 'white', hasHeight });
  return (
    <Cell center column {...props}>
      <CellText>平畑院長</CellText>
      <CellText>16:00～18:00</CellText>
    </Cell>
  );
};

const HirahataSunPM = ({ hasHeight = true, ...props }) => {
  const Cell = makeCell({ backgroundColor: 'white', hasHeight });
  return (
    <Cell center column {...props}>
      <CellText>平畑院長</CellText>
      <CellText>14:00～17:00</CellText>
    </Cell>
  );
};

const HirahataNight = ({ hasHeight = true, ...props }) => {
  const Cell = makeCell({ backgroundColor: 'white', hasHeight });
  return (
    <Cell center column {...props}>
      <CellText>平畑院長</CellText>
      <CellText>18:00～20:00</CellText>
    </Cell>
  );
};

const HirahataMonNight = ({ hasHeight = true, ...props }) => {
  const Cell = makeCell({ backgroundColor: 'white', hasHeight });
  return (
    <Cell center column {...props}>
      <CellText>平畑院長</CellText>
      <CellText>16:00～20:00</CellText>
    </Cell>
  );
};

const Turuta = ({ hasHeight = true, ...props }) => {
  const Cell = makeCell({ backgroundColor: 'white', hasHeight });
  return (
    <Cell center column {...props}>
      <CellText>鶴田医師</CellText>
      <CellText>(17:30迄)</CellText>
      <SmallCellText>内科</SmallCellText>
    </Cell>
  );
};

const Imamu = ({ hasHeight = true, ...props }) => {
  const Cell = makeCell({ backgroundColor: '#b89cc9', hasHeight });
  return (
    <Cell center column {...props}>
      <CellText>今村医師</CellText>
      <CellText>9:00〜12:30</CellText>
    </Cell>
  );
};

const KamiyamaAM01 = ({ hasHeight = true, ...props }) => {
  const Cell = makeCell({ backgroundColor: '#f5c4c4', hasHeight });
  return (
    <Cell center column {...props}>
      <CellText>神山医師</CellText>
      <CellText>9:00〜12:00</CellText>
    </Cell>
  );
};

const KamiyamaAM02 = ({ hasHeight = true, ...props }) => {
  const Cell = makeCell({ backgroundColor: '#f5c4c4', hasHeight });
  return (
    <Cell center column {...props}>
      <CellText>神山医師</CellText>
      <CellText>9:00〜12:30</CellText>
    </Cell>
  );
};

const KamiyamaPM01 = ({ hasHeight = true, ...props }) => {
  const Cell = makeCell({ backgroundColor: '#f5c4c4', hasHeight });
  return (
    <Cell center column {...props}>
      <CellText>神山医師</CellText>
      <CellText>14:00〜16:00</CellText>
    </Cell>
  );
};

const KamiyamaPM02 = ({ hasHeight = true, ...props }) => {
  const Cell = makeCell({ backgroundColor: '#f5c4c4', hasHeight });
  return (
    <Cell center column {...props}>
      <CellText>神山医師</CellText>
      <CellText>14:00〜17:00</CellText>
    </Cell>
  );
};

const HashiuchiAM01 = ({ hasHeight = true, ...props }) => {
  const Cell = makeCell({ backgroundColor: '#d1edf2', hasHeight });
  return (
    <Cell center column {...props}>
      <CellText>橋内医師</CellText>
      <CellText>8:45〜12:00</CellText>
    </Cell>
  );
};

const HashiuchiAM02 = ({ hasHeight = true, ...props }) => {
  const Cell = makeCell({ backgroundColor: '#d1edf2', hasHeight });
  return (
    <Cell center column {...props}>
      <CellText>橋内医師</CellText>
      <CellText>9:00〜11:30</CellText>
    </Cell>
  );
};

const HashiuchiPM01 = ({ hasHeight = true, ...props }) => {
  const Cell = makeCell({ backgroundColor: '#d1edf2', hasHeight });
  return (
    <Cell center column {...props}>
      <CellText>橋内医師</CellText>
      <CellText>13:00〜17:00</CellText>
    </Cell>
  );
};

const HashiuchiPM02 = ({ hasHeight = true, ...props }) => {
  const Cell = makeCell({ backgroundColor: '#d1edf2', hasHeight });
  return (
    <Cell center column {...props}>
      <CellText>橋内医師</CellText>
      <CellText>13:00〜17:30</CellText>
    </Cell>
  );
};

const HashiuchiNight = ({ hasHeight = true, ...props }) => {
  const Cell = makeCell({ backgroundColor: '#d1edf2', hasHeight });
  return (
    <Cell center column {...props}>
      <CellText>橋内医師</CellText>
      <CellText>18:30～20:00</CellText>
    </Cell>
  );
};

const Ookura = ({ hasHeight = true, ...props }) => {
  const Cell = makeCell({ backgroundColor: '#9dd29c', hasHeight });
  return (
    <Cell center column {...props}>
      <CellText>大倉医師</CellText>
      <CellText>18:00〜20:00</CellText>
    </Cell>
  );
};

const MatsumotoAM = ({ hasHeight = true, ...props }) => {
  const Cell = makeCell({ backgroundColor: '#fffbce', hasHeight });
  return (
    <Cell center column {...props}>
      <CellText>松本医師</CellText>
      <CellText>9:00～11:30</CellText>
    </Cell>
  );
};

const MatsumotoPM = ({ hasHeight = true, ...props }) => {
  const Cell = makeCell({ backgroundColor: '#fffbce', hasHeight });
  return (
    <Cell center column {...props}>
      <CellText>松本医師</CellText>
      <CellText>13:00～17:00</CellText>
    </Cell>
  );
};

const Schedule = () => {
  const { size } = useTrackedState();
  return (
    <SubLayout topTitle="受付時間" size={size}>
      <div>
        <P
          css={`
            width: fit-content;
            margin: 0 auto;
          `}
        >
          <ul>
            <li>木・土・日の受付は17:00までとなっております。</li>
            <li>
              風邪症状がある方は、当院受付時間内に窓口まで直接お越しいただき、風邪症状がある事を申し出てください。
            </li>
          </ul>
        </P>
      </div>

      <Flex
        justifyAround={size === 'lg'}
        column={size !== 'lg'}
        center={size !== 'lg'}
        css={`
          margin-bottom: 50px;
        `}
      >
        {/*  
        <WeeklySchedule size={size} headerBackgroundColor="#efefef" />
         
        <Flex
          column={size === 'lg'}
          justifyEnd={size === 'lg'}
          justifyBetween={size === 'sm'}
        ></Flex>
        */}
      </Flex>

      <H3 size={size}>担当医・診療科スケジュール</H3>

      <Flex
        column
        css={`
          margin: 20px 0;
        `}
      >
        <span
          css={`
            color: #666666;
            font-size: 14px;
          `}
        >
          休診・担当医変更のお知らせ
        </span>
        <div
          css={`
            border: solid 1px #707070;
            border-radius: 6px;
            max-height: 100px;
            overflow-y: scroll;
          `}
        >
          <div
            css={`
              margin: 10px;
            `}
          >
            <div>
              <span
                css={`
                  font-size: 14px;
                  color: #ea001b;
                `}
              >
                <ul>
                  <li>3月3日(午前)、19日、26日　神山医師　休診となります</li>
                  <li>2月15日、16日、18日　橋内医師　休診となります</li>
                </ul>
              </span>
            </div>
          </div>
        </div>
        <div
          css={`
            margin-top: 1.5rem;
            font-weight: 600;
          `}
        >
          松本医師は、諸般の事情により令和6年12月27日（金）の診療をもちまして退職することになりました。
          <br />
          通院中の患者様には大変ご不便とご迷惑をお掛け致しますがご理解頂けますようお願い申し上げます。
        </div>
      </Flex>
      <Flex
        wrap="wrap"
        justifyBetween={size !== 'sm'}
        center={size === 'sm'}
        column={size === 'sm'}
      >
        <DaySchedule
          day="月曜日"
          morning1={<HirahataAM />}
          afternoon1={<HirahataPM />}
          night1={<HirahataMonNight />}
          morning2={<KamiyamaAM02 />}
          afternoon2={<KamiyamaPM02 />}
          night2={<Close />}
        />
        <DaySchedule
          day="火曜日"
          morning1={<HirahataAM />}
          afternoon1={<HirahataPM />}
          night1={<HirahataNight />}
          morning2={<HashiuchiAM01 />}
          afternoon2={<HashiuchiPM01 />}
          night2={<Close />}
        />
        <DaySchedule
          day="水曜日"
          morning1={<HirahataAM />}
          afternoon1={<HirahataPM />}
          night1={<HirahataNight />}
          morning2={<KamiyamaAM01 />}
          afternoon2={<Close />}
          night2={<Close />}
        />
        <DaySchedule
          day="木曜日"
          morning1={<HashiuchiAM02 />}
          afternoon1={<HashiuchiPM02 />}
          night1={<Close />}
          morning2={<KamiyamaAM02 />}
          afternoon2={<KamiyamaPM02 />}
          night2={<Close />}
        />
        <DaySchedule
          day="金曜日"
          morning1={<HirahataAM />}
          afternoon1={<HirahataPM />}
          night1={<HirahataNight />}
          morning2={<Close />}
          afternoon2={<HashiuchiPM02 />}
          night2={<HashiuchiNight />}
        />
        <DaySchedule
          morningHasInnerCells
          day="土曜日"
          morning1={<HirahataAM />}
          afternoon1={<HirahataPM />}
          night1={<Close />}
          morning2={<Close />}
          afternoon2={<HashiuchiPM01 />}
          night2={<Close />}
        />
        <DaySchedule
          morningHasInnerCells
          day="日曜日"
          morning1={<HirahataAM />}
          afternoon1={<HirahataSunPM />}
          night1={<Close />}
          morning2={<HashiuchiAM02 />}
          afternoon2={<HashiuchiPM01 />}
          night2={<Close />}
        />
        <P>
          祝日は月毎のスケジュールをご参照ください。
          <br />
          <a href={Pdf} download>
            <p>診療スケジュール(PDF)ダウンロード</p>
          </a>
        </P>
        <ul>
          <li>神山医師：内科・甲状腺・糖尿病</li>
          <li>橋内医師：内科</li>
        </ul>
      </Flex>

      <Img src={ScheduleImg} />
    </SubLayout>
  );
};

export default Schedule;
